<template>
  <section>
    <div
      id="addPrinterDrawer"
      class="offcanvas offcanvas-end"
      tabindex="-1"
      aria-labelledby="addPrinterDrawerLabel"
    >

      <b-card
        title="Invite Printer"
        class="pb-auto"
      >
        <form
          ref="addPrinterForm"
          class="form-container"
          @submit.prevent="onSubmit"
        >
          <div class="mb-2 d-block">
            <!-- User-supplied company_name of the company -->
            <div class="form-group">
              <label
                for="company_name"
                class="form-label mb-1"
              >Company Name</label>
              <input
                id="company_name"
                v-model="company_name"
                type="text"
                class="form-control"
                placeholder="Name of Company"
              >
            </div>

            <!-- Company Owner -->
            <div class="form-group">
              <label
                for="full_name"
                class="form-label mb-1"
              >Owner</label>
              <input
                id="full_name"
                v-model="full_name"
                type="text"
                class="form-control"
                placeholder="Owner's Full Name"
              >
            </div>

            <!-- Company Owner's email -->
            <div class="form-group">
              <label
                for="email"
                class="form-label mb-1"
              >Owner's Email</label>
              <input
                id="email"
                v-model="email"
                type="text"
                class="form-control"
                placeholder="Owner's Email Address"
              >
            </div>
          </div>
          <div
            class="d-block align-right"
          >
            <button
              type="reset"
              class="btn btn-outline-secondary d-inline"
              value="Reset"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-primary d-inline ml-1"
            >
              Submit
            </button>
          </div>
        </form>
      </b-card>
    </div>

  </section>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      company_name: '',
      full_name: '',
      email: '',
      message: '',
    }
  },
  methods: {
    onSubmit() {
      let resStatusText = ''

      // useJwt.createMyCompany()
      // POST /api/root/company
      // Post a company and an invitation to a person to join as admin for the company.

      useJwt.createPrinterAsRoot({
        company_name: this.company_name,
        full_name: this.full_name,
        email: this.email,
      })
        .then(res => {
          resStatusText = res.statusText
          console.log('createMyCompany() full response: ', res)
          this.message = `${resStatusText}`

          // Info notification on submit
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `New printer ${res.statusText.toLowerCase().trim()}`,
              text: `Invite sent to ${this.company_name} with owner ${this.full_name} ${res.statusText.toLowerCase().trim()}.`,
              icon: 'UserPlusIcon',
              variant: 'info',
            },
          })
        })
        .catch(err => {
          // console.dir(err.response)
          console.error(`addPrinter error: ${err.response.status} ${err.response.statusText}`)

          // Error notification on failure
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Printer ${err.statusText}`,
              text: `Error: ${err.response.status} ${err.response.statusText}.`,
              icon: 'ExclamationTriangle',
              variant: 'error',
            },
          })
        })
    },
  },
}
</script>

<style>
  .align-right {
    text-align: right;
    }
</style>
